<template>
  <section class="pt-4 px-0 px-xl-4">
    <b-container fluid>
      <b-row class="mb-3">
        <b-col>
          <b-card>
            <div class="text-right">
              <b-button-group>
                <b-button
                  :variant="chartView === 'day' ? 'primary' : 'secondary'"
                  @click="setViewDay"
                  >Day</b-button
                >
                <b-button
                  :variant="chartView === 'month' ? 'primary' : 'secondary'"
                  @click="setViewMonth"
                  >Month</b-button
                >
                <b-button
                  :variant="chartView === 'year' ? 'primary' : 'secondary'"
                  @click="setViewYear"
                  >Year</b-button
                >
              </b-button-group>
              <line-chart
                :data="chartData"
                :options="options"
                :height="300"
              ></line-chart>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-card title="Controls" class="mb-3">
            <b-card class="mb-3">
              <b-container fluid>
                <b-row>
                  <b-col md="4" class="mb-3">
                    <b-form-datepicker v-model="fromDate" />
                  </b-col>
                  <b-col md="4" class="mb-3">
                    <b-form-datepicker v-model="thruDate" />
                  </b-col>
                  <b-col md="4" class="mb-3">
                    <b-form-input placeholder="Campaing name"></b-form-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    sm="4"
                    md="2"
                    v-for="(control, controlIndex) in controls"
                    :key="controlIndex"
                    class="pr-5 mb-3"
                  >
                    <b-row>
                      <span class="text-center w-100 label small">{{
                        control.label
                      }}</span>
                    </b-row>
                    <b-row>
                      <div class="input-group">
                        <b-form-input
                          size="sm"
                          id="input-live"
                          type="text"
                          v-model="form[control.minName]"
                          placeholder="Min"
                          @keyup="updateStep(controlIndex)"
                        ></b-form-input>
                        <b-form-input
                          size="sm"
                          type="text"
                          v-model="form[control.maxName]"
                          placeholder="Max"
                          @keyup="updateStep(controlIndex)"
                        ></b-form-input>
                      </div>
                    </b-row>
                    <b-row>
                      <span class="text-center w-100 small"
                        >Step: <strong>{{ control.step }}</strong></span
                      >
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="text-right">
                    <b-button variant="danger"
                      ><font-awesome-icon icon="trash"
                    /></b-button>
                  </b-col>
                </b-row>
              </b-container>
            </b-card>
            <b-button variant="primary">
              <font-awesome-icon icon="plus"
            /></b-button>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-card title="Revenue" class="mb-3">
            <b-card class="mb-3" v-for="(row, index) in revenue" :key="index">
              <b-container fluid>
                <b-row>
                  <b-col md="4">
                    <b-form-datepicker v-model="row.fromDate" />
                  </b-col>
                  <b-col md="4">
                    <b-form-datepicker v-model="row.thruDate" />
                  </b-col>
                  <b-col md="3">
                    <b-form-input
                      placeholder="Amount"
                      v-model="row.amount"
                    ></b-form-input>
                  </b-col>
                  <b-col md="1">
                    <b-button variant="danger"
                      ><font-awesome-icon icon="trash"
                    /></b-button>
                  </b-col>
                </b-row>
              </b-container>
            </b-card>
            <b-button variant="primary">
              <font-awesome-icon icon="plus"
            /></b-button>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button block variant="primary" @click="sendData()">Send</b-button>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>
<script>
import LineChart from "../components/LineChart.vue";

export default {
  components: {
    "line-chart": LineChart
  },
  name: "SalesControl",
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            label: "Sales",
            borderColor: "#00A5E0",
            data: [],
            backgroundColor: "#00A5E044"
          },
          {
            label: "Revenue",
            borderColor: "rgb(255, 0, 0)",
            backgroundColor: "#00000000",
            data: [],
            borderDash: [10, 15],
            pointRadius: 0
          }
        ]
      },
      options: {
        response: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback: function(value) {
                  return value.toLocaleString(this.lang, {
                    style: "currency",
                    currency: "EUR",
                    currencyDisplay: "symbol"
                  });
                }
              }
            }
          ]
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem) {
              return tooltipItem.yLabel.toLocaleString(this.lang, {
                style: "currency",
                currency: "EUR",
                currencyDisplay: "symbol"
              });
            }
          }
        }
      },
      controls: [
        {
          label: "Minimal Available Stock Quantity",
          minName: "minimalAvailableStockQuantityMin",
          maxName: "minimalAvailableStockQuantityMax",
          step: 1
        },
        {
          label: "Minimum Days From Goods Arrival",
          minName: "minimumDaysFromGoodsArrivalMin",
          maxName: "minimumDaysFromGoodsArrivalMax",
          step: 1
        },
        {
          label: "unsold Days From Last Purchase",
          minName: "unsoldDaysFromLastPurchaseMin",
          maxName: "unsoldDaysFromLastPurchaseMax",
          step: 1
        },
        {
          label: "minimum Days Stock",
          minName: "minimumDaysStockMin",
          maxName: "minimumDaysStockMax",
          step: 1
        },
        {
          label: "minimum Discount",
          minName: "minimumDiscountMin",
          maxName: "minimumDiscountMax",
          step: 1
        },
        {
          label: "maximum Discount",
          minName: "maximumDiscountMin",
          maxName: "maximumDiscountMax",
          step: 1
        },
        {
          label: "minimum Days Duration",
          minName: "minimumDaysDurationMin",
          maxName: "minimumDaysDurationMax",
          step: 1
        },
        {
          label: "maximum Days Duration",
          minName: "minimumDaysDurationMin",
          maxName: "minimumDaysDurationMax",
          step: 1
        },
        {
          label: "minimum Price",
          minName: "minimumPriceMin",
          maxName: "minimumPriceMax",
          step: 1
        },
        {
          label: "items Number To Discount",
          minName: "itemsNumberToDiscountMin",
          maxName: "itemsNumberToDiscountMax",
          step: 1
        }
      ],
      form: {
        minimalAvailableStockQuantityMin: null,
        minimalAvailableStockQuantityMax: null,
        minimumDaysFromGoodsArrivalMin: null,
        minimumDaysFromGoodsArrivalMax: null,
        unsoldDaysFromLastPurchaseMin: null,
        unsoldDaysFromLastPurchaseMax: null,
        minimumDaysStockMin: null,
        minimumDaysStockMax: null,
        minimumDiscountMin: null,
        minimumDiscountMax: null,
        maximumDiscountMin: null,
        maximumDiscountMax: null,
        minimumDaysDurationMin: null,
        minimumDaysDurationMax: null,
        maximumDaysDurationMin: null,
        maximumDaysDurationMax: null,
        minimumPriceMin: null,
        minimumPriceMax: null,
        itemsNumberToDiscountMin: null,
        itemsNumberToDiscountMax: null,
        salesAmountsBrackets: []
      },
      fromDate: null,
      thruDate: null,
      chartView: "day",
      revenue: [
        {
          fromDate: "2021-01-01 00:00:00",
          thruDate: "2021-12-31 23:59:59",
          amount: 3600000
        }
      ]
    };
  },
  beforeMount() {
    this.setChart();
  },
  methods: {
    gcdTwoNumbers: function(x, y) {
      x = Math.abs(x);
      y = Math.abs(y);
      while (y) {
        const t = y;
        y = x % y;
        x = t;
      }
      return x;
    },
    updateStep: function(controlIndex) {
      if (
        isNaN(this.form[this.controls[controlIndex].minName]) ||
        isNaN(this.form[this.controls[controlIndex].maxName])
      )
        return false;
      this.controls[controlIndex].step = this.gcdTwoNumbers(
        Number(this.form[this.controls[controlIndex].maxName]),
        Number(this.form[this.controls[controlIndex].minName])
      );
    },
    sendData: function() {
      this.$http
        .post(`${process.env.VUE_APP_API_ENDPOINT}user`, this.form)
        .then(res => {
          this.registered = res.data.success;
        })
        .catch(err => {
          this.error = err;
        });
    },
    setChart: function() {
      this.step = 1;
      this.$http
        .get(`${process.env.VUE_APP_API_ENDPOINT}chart/sales/${this.chartView}`)
        .then(res => {
          this.setDataChart(res.data);
          this.setTargetDataChart();
        })
        .catch(err => {
          this.error = err;
        });
    },
    setDataChart: function(data) {
      this.chartData.labels = [];
      this.chartData.datasets[0].data = [];
      let that = this;
      data.forEach(function(element) {
        let date = new Date(element.date.replace(" ", "T"));
        let label = that.$t("monthName")[date.getMonth()];
        if (that.chartView === "day") label = date.getDate() + " " + label;
        else if (that.chartView === "year") label = date.getFullYear();
        that.chartData.labels.push(label);
        that.chartData.datasets[0].data.push(element.value);
      });
    },
    setTargetDataChart: function() {
      this.chartData.datasets[1].data = Array(
        this.chartData.datasets[0].data.length
      ).fill(this.getAverageRevenue());
    },
    getAverageRevenue: function() {
      if (this.chartView === "day")
        return (
          this.revenue[0].amount / 12 / this.chartData.datasets[0].data.length
        );
      else if (this.chartView === "month")
        return this.revenue[0].amount / this.chartData.datasets[0].data.length;
      return this.revenue[0].amount;
    },
    setViewDay: function() {
      this.chartView = "day";
      this.setChart();
    },
    setViewMonth: function() {
      this.chartView = "month";
      this.setChart();
    },
    setViewYear: function() {
      this.chartView = "year";
      this.setChart();
    }
  }
};
</script>

<style>
.label {
  height: 40px;
}
@media only screen and (max-width: 1000px) {
  .label {
    height: 80px;
  }
}
@media only screen and (max-width: 768px) {
  .label {
    height: 20px;
  }
}
</style>
